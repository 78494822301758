import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { submitQuoteForm } from '../../redux/slice/quoteSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function QuoteForm({ type, data }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [productService, setProductService] = useState('');
    const [formData, setFormData] = useState({
        full_name: '',
        email: '',
        phone: '',
        company_name: '',
        product_service: '',
        quantity: '',
        budget: '',
        delivery_date: '',
        additional_files: null,
        description: '',
        related_id: null,
        related_type: type,
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Populating product/service name and setting related ID
    useEffect(() => {
        if (data) {
            let name = '';
            if (type === 'category' && data.category) {
                name = data.category.name;
            } else if (type === 'service' || type === 'product') {
                name = data.name;
            }
            setProductService(name);
            setFormData(prevData => ({
                ...prevData,
                product_service: name,
                related_id: type === 'category' ? data.category_id : data.id,
            }));
        }
    }, [data, type]);

    // Form validation function
    const validate = () => {
        const newErrors = {};

        if (!formData.full_name.trim()) newErrors.full_name = 'Full name is required';
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email address is invalid';
        }
        if (!formData.phone.trim()) {
            newErrors.phone = 'Phone number is required';
        } else if (!/^\d{10}$/.test(formData.phone)) {
            newErrors.phone = 'Phone number must be exactly 10 digits';
        }
        if (!formData.budget.trim()) newErrors.budget = 'Budget is required';
        if (!formData.description.trim()) newErrors.description = 'Description is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle form field changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle file changes, validate the file type and size
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        const maxSize = 2 * 1024 * 1024; // 2MB

        if (file) {
            if (!allowedTypes.includes(file.type)) {
                Swal.fire({
                    title: 'Error!',
                    text: 'Only PDF, DOC, and DOCX files are allowed.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'my-custom-button'
                    }
                })
                return;
            }
            if (file.size > maxSize) {
                Swal.fire({
                    title: 'Error!',
                    text: 'File size must be less than 2MB.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'my-custom-button'
                    }
                })
                return;
            }
            setFormData(prevData => ({
                ...prevData,
                additional_files: file,
            }));
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) return;

        setIsSubmitting(true);
        const formDataToSend = new FormData();
        for (const key in formData) {
            if (key === 'additional_files' && !formData[key]) continue; // Ignore if no file uploaded
            formDataToSend.append(key, formData[key]);
        }

        try {
            const response = await dispatch(submitQuoteForm(formDataToSend)).unwrap();
            if (response.success) {
                Swal.fire({
                    title: 'Success!',
                    text: 'Quote submitted successfully!',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'my-custom-button'
                    }
                }).then(() => {
                     // Define route mapping for each type
                    const routeMap = {
                        product: "/product",
                        category: "/",
                        service: "/service-list"
                    };

                    // Navigate based on the type, defaulting to home if type is unrecognized
                    navigate(routeMap[type] || "/");
                });

                setFormData({
                    full_name: '',
                    email: '',
                    phone: '',
                    company_name: '',
                    product_service: productService,
                    quantity: '',
                    budget: '',
                    delivery_date: '',
                    additional_files: null,
                    description: '',
                    related_id: '',
                    related_type: type,
                });
            } else {
                
                Swal.fire({
                    title: 'Error!',
                    text: response.message || 'Failed to submit the form.' ,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'my-custom-button'
                    }
                })

            }
        } catch (error) {
           
            Swal.fire({
                title: 'Error!',
                text: error.message || 'Failed to submit the form. Please try again.',
                icon: 'error',
                confirmButtonText: 'Cool'
            })
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <React.Fragment>
            <div className="section padding_layout_1">
                <Container>
                    <Row>
                        <Col xs="12" sm="12" md="12" lg="2" xl="2"></Col>
                        <Col xs="12" sm="12" md="12" lg="8" xl="8">
                            <Row>
                                <div className="full">
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                        <div className="main_heading text_align_center">
                                            <h2>REQUEST QUOTE</h2>
                                        </div>
                                        <form className="form_contant" onSubmit={handleSubmit}>
                                            <fieldset>
                                                <Row>
                                                    <Col xs="12" sm="12" md="12" lg="12" className="field">
                                                        <input className="field_custom" name="full_name" placeholder="Full Name *" type="text" value={formData.full_name} onChange={handleChange} />
                                                        {errors.full_name && <p className="text-danger mb-3">{errors.full_name}</p>}
                                                    </Col>
                                                    <Col xs="12" sm="12" md="6" lg="6" className="field">
                                                        <input className="field_custom" name="email" placeholder="Email Address *" type="email" value={formData.email} onChange={handleChange} />
                                                        {errors.email && <p className="text-danger mb-3">{errors.email}</p>}
                                                    </Col>
                                                    <Col xs="12" sm="12" md="6" lg="6" className="field">
                                                        <input className="field_custom" name="phone" placeholder="Phone Number *" type="text" value={formData.phone} onChange={handleChange} />
                                                        {errors.phone && <p className="text-danger mb-3">{errors.phone}</p>}
                                                    </Col>
                                                    <div className="field col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                        <input className="field_custom" name="product_service" placeholder="Product/Service *" type="text" value={productService ? productService : formData.product_service} readOnly />
                                                        {errors.product_service && <p className="text-danger mb-3">{errors.product_service}</p>}
                                                    </div>
                                                    <Col xs="12" sm="12" md="6" lg="6" className="field">
                                                        <input className="field_custom" name="company_name" placeholder="Company Name (Optional)" type="text" value={formData.company_name} onChange={handleChange} />
                                                    </Col>
                                                    <div className="field col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                        <input className="field_custom" name="quantity" placeholder="Quantity/Units" type="number" value={formData.quantity} onChange={handleChange} />
                                                        {errors.quantity && <p className="text-danger mb-3">{errors.quantity}</p>}
                                                    </div>
                                                    <div className="field col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                        <input className="field_custom" name="budget" placeholder="Estimated Budget *" type="number" value={formData.budget} onChange={handleChange} />
                                                        {errors.budget && <p className="text-danger mb-3">{errors.budget}</p>}
                                                    </div>
                                                    <div className="field col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-3">
                                                        <input className="field_custom mb-0" name="delivery_date" placeholder="Expected Delivery Date" type="date" value={formData.delivery_date} onChange={handleChange} />
                                                        <small className="text-muted">Expected delivery date selection (Optional)</small>
                                                        {errors.delivery_date && <p className="text-danger">{errors.delivery_date}</p>}
                                                    </div>
                                                    <div className="field col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-3">
                                                        <input className="field_custom mb-0" name="additional_files" type="file" onChange={handleFileChange} />
                                                        <small className="text-muted">Accepted file types: DOCX, DOC, PDF. (Optional)</small>
                                                        {errors.additional_files && <p className="text-danger">{errors.additional_files}</p>}
                                                    </div>
                                                    <div className="field col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <textarea className="field_custom" name="description" placeholder="Description of Requirements *" value={formData.description} onChange={handleChange}></textarea>
                                                        {errors.description && <p className="text-danger">{errors.description}</p>}
                                                    </div>
                                                    <div className="center">
                                                        <button className="btn main_bt" type="submit" disabled={isSubmitting}>
                                                            {isSubmitting ? 'Submitting...' : 'Submit Now'}
                                                        </button>
                                                    </div>
                                                </Row>
                                            </fieldset>
                                        </form>
                                    </Col>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
